import React from "react"
import Style from "../styles/main.css"
import StyleResponsive from "../styles/main-responsive.css"
import HeaderDesktop from "../components/headerDesktop"
import HeaderMobile from "../components/headerMobile"
import Banner from "../components/sections/banner"
import Find from "../components/sections/find"
import Service from "../components/sections/service"
import Category from "../components/sections/category"
import Price from "../components/sections/price"
import Footer from "../components/footer"

export default function Home() {
  return (
    <div className="irn-wrapper">
      <div className="irn-wrapper-inner">
        <HeaderDesktop />
        <HeaderMobile />
        <Banner />
      </div>
      <div className="irn-content">
      <Find />
      <Service />
      <Category />
      </div>
      <Footer />
    </div>
    )
}
