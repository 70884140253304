import React from "react"
import Style from "../../styles/main.css"
import StyleResponsive from "../../styles/main-responsive.css"

export default function Find() {
  return (
        <section className="wrapper-find irn-section-title-holder">
            <svg version="1.1" class="mkdf-animated-svg mkdf-appeared" id="mkdf-animated-svg-610" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="49px" height="49px" viewBox="0 0 49 49" enable-background="new 0 0 49 49">
                <path xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#D5B981" stroke-width="1.75" stroke-linecap="round" stroke-miterlimit="10" d="M20.365,25.008   c0,0-2.723,1.645-4.163,4.133c-1.439,2.49-0.817,4.824-0.7,5.719c0.116,0.896-0.156,2.296,0.428,4.086s3.151,4.941,5.797,6.458   c2.912,1.671,3.242,3.661,3.272,3.657c0.03-0.002,0.36-1.98,3.273-3.651c2.646-1.517,5.212-4.67,5.797-6.458   c0.582-1.79,0.31-3.19,0.427-4.086c0.115-0.895,0.739-3.229-0.701-5.719c-1.438-2.49-4.159-4.013-4.163-4.145   s2.725-1.653,4.163-4.143c1.44-2.491,0.816-4.825,0.701-5.72c-0.117-0.895,0.155-2.296-0.427-4.084   c-0.585-1.791-3.151-4.942-5.797-6.458c-2.913-1.67-3.226-3.66-3.273-3.658c-0.049,0.002-0.36,1.992-3.272,3.662   c-2.646,1.518-5.213,4.669-5.797,6.458c-0.583,1.791-0.312,3.19-0.428,4.085c-0.117,0.895-0.739,3.229,0.7,5.719   C17.642,23.355,20.365,25.008,20.365,25.008" class="RMNQAmVi_0"></path>
                <path xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#D5B981" stroke-width="1.75" stroke-linecap="round" stroke-miterlimit="10" d="M21.728,28.282   c0,0-0.763,3.09-0.021,5.866c0.743,2.78,2.833,3.989,3.549,4.539c0.715,0.552,1.513,1.734,3.19,2.588   c1.681,0.854,5.723,1.264,8.667,0.468c3.239-0.878,4.816,0.356,4.898,0.271c0.082-0.084-1.146-1.655-0.269-4.896   c0.799-2.943,0.386-6.988-0.467-8.667c-0.853-1.678-2.035-2.475-2.587-3.19c-0.55-0.716-1.76-2.807-4.538-3.549   c-2.779-0.743-5.779,0.104-5.874,0.012c-0.098-0.09,0.755-3.094,0.014-5.873c-0.743-2.778-2.834-3.989-3.55-4.539   c-0.715-0.55-1.513-1.733-3.19-2.585c-1.678-0.854-5.723-1.267-8.667-0.469c-3.24,0.878-4.867-0.308-4.899-0.272   C7.936,8.041,9.139,9.65,8.261,12.891c-0.798,2.943-0.385,6.987,0.468,8.666c0.853,1.677,2.035,2.475,2.586,3.19   c0.55,0.716,1.76,2.807,4.539,3.549C18.633,29.039,21.728,28.282,21.728,28.282" class="RMNQAmVi_1"></path>
                <path xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#D5B981" stroke-width="1.75" stroke-linecap="round" stroke-miterlimit="10" d="M25.007,29.638   c0,0,1.644,2.724,4.134,4.162c2.49,1.44,4.824,0.817,5.719,0.7s2.295,0.157,4.084-0.427c1.791-0.584,4.942-3.151,6.46-5.798   c1.669-2.912,3.659-3.242,3.657-3.273c-0.004-0.028-1.982-0.359-3.651-3.271c-1.518-2.646-4.669-5.213-6.46-5.797   c-1.789-0.583-3.189-0.312-4.084-0.427c-0.895-0.118-3.23-0.74-5.719,0.701c-2.49,1.438-4.014,4.159-4.146,4.162   c-0.132,0.003-1.654-2.724-4.144-4.162c-2.49-1.44-4.825-0.818-5.719-0.701c-0.895,0.117-2.295-0.156-4.085,0.427   c-1.79,0.583-4.941,3.151-6.458,5.798c-1.671,2.911-3.66,3.224-3.658,3.271c0.002,0.049,1.992,0.361,3.663,3.273   c1.517,2.646,4.668,5.214,6.458,5.798c1.79,0.584,3.19,0.312,4.085,0.427c0.895,0.117,3.229,0.74,5.719-0.7   C23.354,32.361,25.007,29.638,25.007,29.638" class="RMNQAmVi_2"></path>
                <path xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#D5B981" stroke-width="1.75" stroke-linecap="round" stroke-miterlimit="10" d="M28.281,28.275   c0,0,3.089,0.762,5.866,0.021c2.78-0.744,3.99-2.834,4.54-3.55c0.55-0.714,1.734-1.512,2.587-3.19   c0.853-1.679,1.264-5.722,0.467-8.666c-0.877-3.24,0.311-4.853,0.272-4.9c-0.038-0.047-1.655,1.146-4.896,0.268   c-2.942-0.798-6.987-0.385-8.665,0.469c-1.678,0.853-2.477,2.035-3.191,2.585c-0.716,0.549-2.806,1.761-3.549,4.539   c-0.743,2.779,0.104,5.779,0.013,5.874c-0.091,0.095-3.095-0.756-5.874-0.013c-2.778,0.742-3.989,2.833-4.539,3.55   c-0.55,0.713-1.733,1.513-2.586,3.19c-0.853,1.677-1.266,5.722-0.468,8.665c0.878,3.24-0.391,4.778-0.272,4.899   c0.119,0.123,1.664-1.153,4.904-0.275c2.943,0.798,6.988,0.386,8.666-0.468c1.678-0.852,2.476-2.036,3.191-2.586   c0.715-0.551,2.805-1.761,3.55-4.539C29.038,31.37,28.281,28.275,28.281,28.275" class="RMNQAmVi_3"></path>
            </svg>
            <div className="divider"></div>
            <h2 className="irn-st-title">Me Trouver</h2>
            <h6 className="irn-st-text">Je vous accueille pour mes consultations<br/> A l'Espace Santé et Mieux-Etre des Chanterelles<br/>dans les locaux de Corinne LABORIE (un véritable havre de paix !!)<br/>274, rue des Chanterelles, 45160 OLIVET<br/>ou<br/>A mon domicile (MARCILLY EN VILLETTE)</h6>
            <a href="#contact" class="irn-btn mkdf-btn-medium irn-btn-solid irn-btn-outline-border">
                <span class="irn-btn-text">Contactez-moi</span>
                <span class="irn-btn-borders-holder">
                    <span class="irn-btn-borders irn-border1"></span>
                    <span class="irn-btn-borders irn-border2"></span>
                    <span class="irn-btn-borders irn-border3"></span>
                    <span class="irn-btn-borders irn-border4"></span>
                </span>
            </a>
        </section>
    )
}