import React from "react"
import Style from "../../styles/main.css"
import StyleResponsive from "../../styles/main-responsive.css"

export default function Service() {
  return (
        <section className="wrapper-service irn-row-grid-section-wrapper ">
            <div className="irn-row-grid-section d-flex">
                <div className="col-flex">
                    <div className="irn-iwt clearfix  irn-iwt-icon-top irn-iwt-icon-medium">
                        <div className="irn-iwt-icon">
                            <span className="irn-iwt-icon-border">
                                <img width="60" height="50" src={'/image/picto-service-hypnose-thera.png'} className="attachment-full size-full" alt="hypnose thérapeutique" /> 
                                <span className="irn-iwt-border-holder"></span>
                            </span>
                        </div>
                        <div className="irn-iwt-content">
                            <h5 className="irn-iwt-title">
                            <span className="irn-iwt-title-text">Hypnose thérapeutique<br/>en 2 séances</span>
                            </h5>
                            <p className="irn-subtitle">Une  1ère séance d’1 heure : questions – acupression et hypnose de relaxation en fin de séance (10mn)<br/>
                            Une 2ème séance d’1 heure : Hypnose
                            </p>
                            <p className="irn-iwt-text">
                            gestion du stress – troubles alimentaires – troubles du sommeil – perte de poids – phobies - deuil …
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-flex">
                    <div className="irn-iwt clearfix  irn-iwt-icon-top irn-iwt-icon-medium">
                        <div className="irn-iwt-icon">
                            <span className="irn-iwt-icon-border">
                                <img width="60" height="50" src={'/image/picto-service-hypnose.png'} className="attachment-full size-full" alt="hypnose de relaxation" /> 
                                <span className="irn-iwt-border-holder"></span>
                            </span>
                        </div>
                        <div className="irn-iwt-content">
                            <h5 className="irn-iwt-title">
                            <span className="irn-iwt-title-text">Hypnose<br/>de relaxation<br/>individuelle</span>
                            </h5>
                            <p className="irn-subtitle">1 séance d'1h</p>
                            <p className="irn-iwt-text">
                            Elle vous permet, dans ce contexte si particulier que nous vivons, de vous retrouver, de lâcher- prise, de prendre enfin du temps pour vous.<br/><br/>
                            Je vous conseille d’apporter un plaid pour vous sentir parfaitement ancré(e) dans votre séance.

                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-flex">
                    <div className="irn-iwt clearfix  irn-iwt-icon-top irn-iwt-icon-medium">
                        <div className="irn-iwt-icon">
                            <span className="irn-iwt-icon-border">
                                <img width="60" height="50" src={'/image/picto-service-acupression-detente.png'} className="attachment-full size-full" alt="acupression detente" /> 
                                <span className="irn-iwt-border-holder"></span>
                            </span>
                        </div>
                        <div className="irn-iwt-content">
                            <h5 className="irn-iwt-title">
                            <span className="irn-iwt-title-text">Acupression<br/>détente<br/><br/></span>
                            </h5>
                            <p className="irn-subtitle">1 séance d'1h</p>
                            <p className="irn-iwt-text">
                            Vous vous installerez sur une table de massage.<br/>
                            Une détente totale durant laquelle j’exercerai des pressions sur des points d’acupuncture précis au niveau du dos, des bras, des jambes et du visage.<br/>
                            Je vous conseille d’apporter un plaid pour vous sentir parfaitement ancré(e) dans votre séance ainsi qu’une tenue confortable.

                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-flex">
                    <div className="irn-iwt clearfix  irn-iwt-icon-top irn-iwt-icon-medium">
                        <div className="irn-iwt-icon">
                            <span className="irn-iwt-icon-border">
                                <img width="60" height="50" src={'/image/picto-service-acupression.png'} className="attachment-full size-full" alt="acupression" /> 
                                <span className="irn-iwt-border-holder"></span>
                            </span>
                        </div>
                        <div className="irn-iwt-content">
                            <h5 className="irn-iwt-title">
                            <span className="irn-iwt-title-text">Acupression et traitements anti-cancéreux</span>
                            </h5>
                            <p className="irn-subtitle">1 séance de ¾ d’heure</p>
                            <p className="irn-iwt-text">
                            1 séance si possible un jour avant ou le jour même de votre séance de traitement 
                            pour soulager les effets secondaires indésirables
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}