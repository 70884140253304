import React from "react"
import Style from "../../styles/main.css"
import StyleResponsive from "../../styles/main-responsive.css"

export default function Banner() {
  return (
        <section className="wrapper-banner">
            <div className="divider"></div>
            <div className="wrapper-banner_title">
                Isabelle<br/>
                Hypnothérapie <br /> Acupression
            </div>
            <div className="wrapper-banner_description">
                Bienvenue sur mon site entièrement dédié à votre<br/>bien-être
            </div>
        </section>
    )
}