import React from "react"
import Style from "../../styles/main.css"
import StyleResponsive from "../../styles/main-responsive.css"
import { Link } from "gatsby";

export default function Category() {
  return (
        <section className="wrapper-category d-flex">
            <div className="irn-interactive-banner-holder">
                <Link className="irn-banner-link" to="/hypnoseTherapeutique" target="_self"></Link>
                <div className="irn-banner-image">
                    <img width="800" height="539" src={'/image/bg-protocole.jpg'} className="attachment-full size-full" alt="protocole"/>
                </div>
                <div className="irn-banner-text-holder">
                    <div className="irn-banner-text-outer">
                        <img width="60" height="68" src={'/image/picto-protocole.png'} className="attachment-full size-full" alt="a" /> 
                        <h2 className="irn-banner-title">
                        HYPNOSE THERAPEUTIQUE<br/>MON PROTOCOLE 
                        </h2>
                    </div>
                </div>
            </div>
            <div className="irn-interactive-banner-holder">
                <Link className="irn-banner-link" to="/acupression" target="_self"></Link>
                <div className="irn-banner-image">
                    <img width="800" height="539" src={'/image/bg-acupression.jpg'} className="attachment-full size-full" alt="acupression" />
                </div>
                <div className="irn-banner-text-holder">
                    <div className="irn-banner-text-outer">
                    <img width="60" height="68" src={'/image/picto-acupression.png'} className="attachment-full size-full" alt="a" />
                        <h2 className="irn-banner-title">
                        ACUPRESSION<br/>QU'EST-CE QUE C'EST ? 
                        </h2>
                    </div>
                </div>
            </div>
            <div className="irn-interactive-banner-holder">
                <Link className="irn-banner-link" href="/hypnose" target="_self"></Link>
                <div className="irn-banner-image">
                <img width="800" height="539" src={'/image/bg-hypnose.jpg'} className="attachment-full size-full" alt="hypnose" />
                </div>
                <div className="irn-banner-text-holder">
                    <div className="irn-banner-text-outer">
                    <img width="60" height="68" src={'/image/hand-picto.png'} className="attachment-full size-full" alt="a" />
                    <h2 className="irn-banner-title">
                    HYPNOSE<br/>QU'EST-CE QUE C’EST ? 
                    </h2>
                    </div>
                </div>
            </div>
        </section>
    )
}